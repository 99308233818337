/*
 * 업무구분: 계약관리
 * 화 면 명: MSPPM910P
 * 화면설명: 자동이체미이체 상세조회조건
 * 접근권한: 
 * 작 성 일: 2022.12.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" :show-title="true" title="자동이체미이체 상세조건" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">

        <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
        <ur-box-container direction="column" alignV="start" class="ns-sch-area">
          <SearchCondOrgCM ref="refSearchOrg" v-if="lv_IsMngr" v-model="lv_OrgSearchData" :isFofType="1" :pOrgData="lv_OrgSearchData" @selectResult="fn_SlectCnsltResultPM"/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-input-box mb50">
          <mo-text-field v-model="lv_SearchCustNm" underline placeholder="계약자명을 입력하세요." clearable/>  
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt0 mb50">
          <span class="ns-title mb10">계약구분</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_LpsScCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_LpsScCds" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <!-- 2022-12-26 납입주기 수정부분 : (기존) checkbox 타입 -> (변경) chip 버튼 타입 변경  -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mb50">
          <span class="ns-title">납입주기</span>
          <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="ns-chk-box3">
            <div class="ns-check msp-multi-check">
              <mo-checkbox v-for="(item) in lv_PadCylNms.data" v-model="lv_PadCylNm" :value="item" :key="item" @input="fn_CheckallPadCylNm(item)">{{fn_GetPadCylNmTxt(item)}}</mo-checkbox>
            </div>
          </ur-box-container>
        </ur-box-container>

        <!-- 2022-12-26 자동이체일 수정부분 : (기존) checkbox 타입 -> (변경) chip 버튼 타입 변경 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mb50">
          <span class="ns-title">자동이체일</span>
          <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="ns-chk-box3">
            <div class="ns-check msp-multi-check">
              <mo-checkbox v-for="(item) in lv_AtrDds.data" v-model="lv_AtrDd" :value="item" :key="item" @input="fn_CheckallAtrDd(item)">{{fn_AtrDdTxt(item)}}</mo-checkbox>
            </div>
          </ur-box-container>
        </ur-box-container>

        <!-- 단일 선택 타입 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mt0 mb50">
          <span class="ns-title mb10">유지횟수</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_UkepNtsScCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_UkepNtsScCds" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_099" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetBtnClick">초기화</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    
    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
    <ur-box-container alignV="start" componentid="ur_box_container_033" direction="column">
      <div class="ns-height70"></div>
    </ur-box-container>
    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 //-->
  </ur-page-container>
</template>

<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import pmConstants from '@/config/constants/pmConstants.js'

export default {
  /*****************************************************************************
   * Vue 파일 속성 정의 영역
   ****************************************************************************/
  name: 'MSPPM910P',
  screenId: 'MSPPM910P',
  components: {
    SearchCondOrgCM,
    MspBottomSelect
  },
  props: {
    pIsMngr: Boolean, // 조직combox 권한자여부
    pOrgData: {
      type: Object,
      default: {}
    },
    pSearchCustNm: String, // 계약자명
    pLpsScCd: String,      // 계약구분
    pPadCylNm: String,     // 납입주기
    pAtrDd: String,        // 자동이체일
    pUkepNtsScCd: String,  // 유지횟수
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /*****************************************************************************
   * Life Cycle 함수 정의 영역
   ****************************************************************************/
  created () {
    // 스크롤영역 ID 설정
    this.scrollCompID = 'scroll_' + this.$options.screenId
    console.log('this.scrollCompID', this.scrollCompID)

    // 부모화면으로부터 전달받은 파라미터를 설정
    this.lv_IsMngr = this.$props.pIsMngr // 관리자여부
    this.lv_OrgSearchData = _.cloneDeep(this.$props.pOrgData)     // 조직정보
    this.lv_SearchCustNm = _.cloneDeep(this.$props.pSearchCustNm) // 계약자명
    this.lv_LpsScCd = _.cloneDeep(this.$props.pLpsScCd)           // 계약구분
    this.lv_PadCylNm = this.$props.pPadCylNm !== ''? this.$props.pPadCylNm.split(','): [' ']  // 납입주기
    this.lv_AtrDd = this.$props.pAtrDd !== ''? this.$props.pAtrDd.split(','): [' '] // 자동이체일
    // this.lv_UkepNtsScCd = this.$props.pUkepNtsScCd !== ''? this.$props.pUkepNtsScCd.split(','): [' '] // 유지횟수
    this.lv_UkepNtsScCd = this.$props.pUkepNtsScCd
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /*****************************************************************************
   * 화면 전역 변수 선언 영역
   ****************************************************************************/
  data () {
    return {
      scrollCompID: '', // 스크롤 컴포넌트ID
      lv_SearchCustNm: '', // 계약자명
      // 계약구분
      lv_LpsScCd: '0',
      lv_LpsScCds: pmConstants.SIGN_TYPE_CD,
      // 납입주기
      lv_PadCylNm: [' '],
      lv_PadCylNms: {
        data: pmConstants.PAD_CYL_CD,
        selectedAllItem: true
      },
      // 자동이체일
      lv_AtrDd: [' '],
      lv_AtrDds: {
        data: pmConstants.ATR_DD_CD,
        selectedAllItem: true
      },
      // 유지횟수
      lv_UkepNtsScCd: ' ',
      lv_UkepNtsScCds: pmConstants.UKEP_NTS_SC_CD,
      selectedAllItem: true,
      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
      lv_OrgSearchData: {}, // 조직정보
      lv_RtnOrgSearchData: {}, // 조직정보
      lv_IsMngr: false, // 관리자여부
      lv_AlertPop: '' // bottom alert component
    }
  },
  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultPM()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultPM (pData) {
      if ( this.isShowCnslt ) {
        // this.lv_OrgSearchData = pData
        this.lv_RtnOrgSearchData = pData
        this.allChk = true
      }
      this.isShowCnslt = true
    },

    /******************************************************************************
     * Function명 : fn_CheckallPadCylNm
     * 설명       : 상세조회 납입주기 조건에서
     *              전체 클릭시 전체가 이미 활성화 상태이면
     *              전체를 제외한 다른 모든 값을 활성화하고
     *              전체가 활성화된 상태가 아니면 전체를 제외한 나머지를 활성화
     * @param {Number} pValue - ' ': "전체", "전체" 외 나머지
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_CheckallPadCylNm (pValue) {
      if (pValue === ' ') {
        if (this.lv_PadCylNms.selectedAllItem) {
          this.lv_PadCylNms.selectedAllItem = false
          this.lv_PadCylNm = []
          for (let i = 1; i < this.lv_PadCylNms.data.length; i++) {
            this.lv_PadCylNm.push(this.lv_PadCylNms.data[i])
          }
        } else {
          this.lv_PadCylNms.selectedAllItem = true
          this.lv_PadCylNm = []
          this.lv_PadCylNm.push(this.lv_PadCylNms.data[0])
        }
      } else {
        if (this.lv_PadCylNms.selectedAllItem) {
          this.lv_PadCylNms.selectedAllItem = false
          this.lv_PadCylNm.splice(0,1)
        } else if (this.lv_PadCylNm.length === 0) {
          this.lv_PadCylNms.selectedAllItem = true
          this.lv_PadCylNm.push(this.lv_PadCylNms.data[0])
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_GetPadCylNmTxt
     * 설명       : 상세조회조건의 납입주기 체크박스의 화면에 노출될 명 getter
     * @param {MouseEvent} pValue - 체크박스 value
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_GetPadCylNmTxt (pValue) {
      let t_RtnTxt = ''
      if ( pValue === ' ' ) {
        t_RtnTxt = '전체'
      } else if ( pValue === '12' ) {
        t_RtnTxt = '월납'
      } else if ( pValue === '04' ) {
        t_RtnTxt = '3월납'
      } else if ( pValue === '02' ) {
        t_RtnTxt = '6월납'
      } else if ( pValue === '01' ) {
        t_RtnTxt = '연납'
      }
      return t_RtnTxt
    },

    /******************************************************************************
     * Function명 : fn_CheckallAtrDd
     * 설명       : 상세조회 자동이체일 조건에서
     *              전체 클릭시 전체가 이미 활성화 상태이면
     *              전체를 제외한 다른 모든 값을 활성화하고
     *              전체가 활성화된 상태가 아니면 전체를 제외한 나머지를 활성화
     * @param {Number} pValue - ' ': "전체", "전체" 외 나머지
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_CheckallAtrDd (pValue) {
      this.$nextTick(() => {
        if (pValue === ' ') {
          if (this.lv_AtrDds.selectedAllItem) {
            this.lv_AtrDds.selectedAllItem = false
            this.lv_AtrDd = []
            for (let i = 1; i < this.lv_AtrDds.data.length; i++) {
              this.lv_AtrDd.push(this.lv_AtrDds.data[i])
            }
          } else {
            this.lv_AtrDds.selectedAllItem = true
            this.lv_AtrDd = []
            this.lv_AtrDd.push(this.lv_AtrDds.data[0])
          }
        } else {
          if (this.lv_AtrDds.selectedAllItem) {
            this.lv_AtrDds.selectedAllItem = false
            this.lv_AtrDd.splice(0,1)
          } else if (this.lv_AtrDd.length === 0) {
            this.lv_AtrDds.selectedAllItem = true
            this.lv_AtrDd.push(this.lv_AtrDds.data[0])
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_AtrDdTxt
     * 설명       : 상세조회조건의 자동이체일 체크박스의 화면에 노출될 명 getter
     * @param {MouseEvent} pValue - 체크박스 value
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_AtrDdTxt (pValue) {
      let t_RtnTxt = ''
      if ( pValue === ' ' ) {
        t_RtnTxt = '전체'
      } else if ( pValue === '30' ) {
        t_RtnTxt = '말일'
      } else {
        t_RtnTxt = Number(pValue) + '일'
      }
      return t_RtnTxt
    },

    /******************************************************************************
     * Function명 : fn_ResetBtnClick
     * 설명       : 상세조회 조건 초기화
     *              (상세조회 내 초기화 버튼 클릭시 호출)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_ResetBtnClick (e) {
      // 조직
      this.lv_OrgSearchData = {}
      this.lv_RtnOrgSearchData = {}
      this.lv_SearchCustNm = '' // 계약자명 검색어 초기화

      // 조회유형 초기화
      this.lv_LpsScCd = this.lv_LpsScCds[0].value  // 계약구분 초기화
      this.lv_PadCylNm = [this.lv_PadCylNms.data[0]] // 납입주기 초기화
      this.lv_PadCylNms.selectedAllItem = true
      this.lv_AtrDd = [this.lv_AtrDds.data[0]] // 자동이체일 초기화
      this.lv_AtrDds.selectedAllItem = true
      this.lv_UkepNtsScCd = ' ' // 유지횟수 초기화
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_Search () {
      // 유효성 체크
      if ( !this.fn_ValidationCheck() ) return false

      // 납입주기
      let padCylNm = ''
      let idx1 = ''
      if (this.lv_PadCylNm[0].trim() === '') idx1 = 1
      else idx1 = 0
      for (let index = idx1; index < this.lv_PadCylNm.length; index++) {
        padCylNm += this.lv_PadCylNm[index]
        if (index !== this.lv_PadCylNm.length - 1) {
          padCylNm += ','
        }
      }

      // 자동이체일
      let atrDd = ''
      let idx2 = ''
      if (this.lv_AtrDd[0].trim() === '') idx2 = 1
      else idx2 = 0
      for (let index = idx2; index < this.lv_AtrDd.length; index++) {
        atrDd += this.lv_AtrDd[index]
        if (index !== this.lv_AtrDd.length - 1) {
          atrDd += ','
        }
      }

      // return data set
      let t_RtnData = {
        // pOrgData: _.cloneDeep(this.lv_OrgSearchData), // 조직정보
        pOrgData: _.cloneDeep(this.lv_RtnOrgSearchData),
        searchCustNm: this.lv_SearchCustNm, // 계약자명
        lpsScCd: this.lv_LpsScCd, // 계약구분 
        padCylNm: padCylNm, // 납입주기
        atrDd: atrDd, // 자동이체일
        ukepNtsScCd: this.lv_UkepNtsScCd  // 유지횟수
      }
      this.$emit('onPopupSearch', {'rtnData': t_RtnData})
    },

    /******************************************************************************
     * Function명 : fn_ValidationCheck
     * 설명       : 상세조회조건 유효성 체크
     * @param {none}
     * @return {Boolean} - true: 유효함, false: 유효하지 않음
     ******************************************************************************/
    fn_ValidationCheck () {
      // 조직 콤보박스 권한자인 경우 컨설턴트 입력여부 체크
      if ( this.lv_IsMngr ) {
        if ( _.isEmpty(this.lv_RtnOrgSearchData) || this.lv_RtnOrgSearchData.cnslt.length === 0 ||
          this.lv_RtnOrgSearchData.cnslt.key === undefined ||
          this.lv_RtnOrgSearchData.cnslt.key === null ||
          this.lv_RtnOrgSearchData.cnslt.key === '1' ||
          this.lv_RtnOrgSearchData.cnslt.key === '0' ) {
          this.getStore('confirm').dispatch('ADD', '정보보호 요청 대상건 입니다.')  // EPMC005
          return false
        }
      }
      return true
    },

    /******************************************************************************
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.ui-frame__scroll-position-top .pm902p-top-btn-display-none {
  display: none;
}
</style>
