/*
 * 업무구분: 계약관리
 * 화 면 명: MSPPM910M
 * 화면설명: 자동이체미이체
 * 작 성 일: 2022.12.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="자동이체미이체" :show-title="false" type="subpage" :topButton="true" @on-scroll-bottom="fn_LoadingData()">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
       <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_HistoryBack()"/>
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '자동이체 미이체' : '자동이체 미이체'}}</div>
              <div slot="action" @click="fn_OpenMSPPM910P">
                <mo-icon>search</mo-icon>
              </div>
            </mo-top-bar>
          </template> 
          <!-- 헤더 타이틀 영역 //-->

          <!-- collapsing 영역 -->
          <template #collapsing>
            <p class="text-info-box">자동이체일 + 1영업일에만 문자발송 가능하며, 문자수신이 불가한 고객은 발송할 수 없습니다.</p>
          </template>
          <!-- collapsing 영역 //-->

          <!-- stickey 영역 -->
          <template #sticky>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-sum">
              <mo-list-item>
                <mo-checkbox v-model="isOneOrMoreCheck" size="small" @click="fn_CheckAll()" :disabled="isEmptyView || checkDisabled"/>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{totCnt}}</strong> 건</span>
                    <span class="sum" v-else><strong class="crTy-blue3">{{checkCount}}</strong> 명 선택</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </template>
          <!-- stickey 영역 //-->

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area msp-list-area">
              <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-contract-list ns-check-list">
                <mo-list :list-data="pMAtrUnTrsfCseVO">
                  <template #list-item="{ index, item }">
                    <mo-list-item :class="{'on' : item.checked}" @click.native.stop="fn_OnClickListItem(index)"> <!-- on item이 체크되어 선택되었음을 표시  --->
                      <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount(item)" :disabled="isEmptyView || checkDisabled" :ref="`chk${index}`"/>
                      <div class="list-item__contents">
                        <!-- 상품명 -->
                        <div class="list-item__contents__title">
                          <span class="name txtSkip" @click.stop="fn_ContNoDecrypt(item.contNoEnc, item.chnlCustId01)">{{item.prdtNm}}</span>
                        </div>
                        <!-- 보험료 -->
                        <div class="list-item__contents__info">
                           <strong class="amount crTy-blue3" @click.stop="fn_ContNoDecrypt(item.contNoEnc, item.chnlCustId01)">{{$commonUtil.numberWithCommas(item.prm.amount)}}원</strong>
                        </div>
                        <!-- 고객명, 의뢰, 미이체 -->
                        <div class="list-item__contents__info">
                          <div class="info_box">
                            <!-- 고객카드 없음 240129 추가 -->
                            <span class="name w-break" :style="!item.chnlCustId01.trim() ? 'padding-right: 9px;' : null"
                              :class="!item.chnlCustId01.trim() ? 'need-cs-card' : null" @click.stop="fn_OpenCustInfoBs(item)">
                              {{ item.contrNm }}
                            </span>
                            <em>|</em>
                            <!-- 고객카드 없음 240129 추가 -->
                            <span>의뢰 <b class="crTy-blue2">{{item.trsfAskSmtotCnt}}</b></span><em>|</em>
                            <span>미이체 <b class="crTy-orange2">{{item.unTrsfCnt}}</b></span>
                          </div>
                        </div>
                        <!-- 유지사항, 은행명, 이체희망일 -->
                        <div class="list-item__contents__info">
                          <div class="info_box">
                            <span><span class="crTy-bk7 fs14 pr10">유지사항</span><span>{{$commonUtil.dateDashFormat(item.ltpadYm)}}</span></span><em>|</em>
                            <span>{{item.ltpadNts}}회</span>
                            <span class="dsD fexTy5">{{item.bnkNm}}<span class="ml5 mr5">/</span> <span>{{Number(item.trsfDd)}}일</span></span>
                          </div>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>

              <!-- NoData 영역 -->
              <ur-box-container v-else alignV="start" componentid="ur_box_container_003" direction="column" class="ns-contract-list no-data">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span>데이터가 존재하지 않습니다.</span>
                    </div>
                  </div>
                </mo-list-item>
              </ur-box-container>

              <!-- 바텀시트1 : 자동이체일 + 1일 미도래 오류 Alert 팝업입니다. <2022-12-26 class : msp-bottomSt-alert 추가 수정> -->
              <mo-bottom-sheet ref="bottomsheetMsg1" class="ns-bottom-sheet msp-bottomSt-alert">
                <div class="customer-info-txt txt-center">
                  <p class="ment">자동이체일+1영업일에만 문자발송 가능합니다.</p>
                </div>
                <template v-slot:action>
                  <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
                    <div class="relative-div">
                      <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet" name="확인">확인</mo-button>
                    </div>
                  </ur-box-container>
                </template>
              </mo-bottom-sheet>

              <!-- Toast -->
              <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

              <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
              <div v-if="pMAtrUnTrsfCseVO.length > 0" class="ns-height40"></div>

            </ur-box-container>
            <!-- TOP 버튼 영역 //-->
          </template>  
          <!-- scroll 영역 //--> 

        </mo-collapsing-header-view>
      </div>
    </ur-box-container>

    <!-- 계약 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>

  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPPM910P from '@/ui/pm/MSPPM910P' // 자동이체미이체 상세조회
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPPM910M',
  // 현재 화면 ID
  screenId: 'MSPPM910M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPPM910P
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      lv_HeaderviewScrollCompID: '',
      toastMsg: '', // 문자발송 toast 문구

      // 제어 변수
      isMngr: false,            // 지점장 여부
      isOneOrMoreCheck: false,  // 전체선택
      is5PlusWorkingDay: false, // 이체가능일 여부
      checkDisabled: false,     // 지점장 체크박스 선택 가능여부
      isLoading: false,
      isEmptyView: false,       // 데이터 없음 화면

      // 팝업 연동
      popup910: {}, // MSPPM910P 상세조회
      pOrgData910M: {}, // 최근 조직 조회 데이터
      
      // 조회 변수
      // 자동이체미이체 리스트 조회
      searchKeyword: '', // 고객명
      nowDate: this.$bizUtil.getDateToString(new Date(), ''),
      pMAtrUnTrsfCseVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_inputParam: { // 서비스호출 Obj
        hofOrgNo: '', // 사업부조직번호
        dofOrgNo: '', // 지역단조직번호
        fofOrgNo: '', // 지점조직번호
        cnsltNo: '', // 컨설턴트번호
        orgNo: '',  // 지점조직번호(TSSPM006D)
        lpsScCd: '0',  // 계약구분
        padCylNm: '', // 납입주기
        atrDd: '',    // 자동이체일
        ukepNtsScCd: ' ',  // 유지횟수
      },
      totCnt: 0, // 누적 총 조회건수
      // 페이징
      stndKeyList: [],  // 더보기 key
      stndKeyVal: '',
      pageRowCnt: '200', // 페이지 카운트
      // 자동이체일 조회
      before5WorkDay: '',
      next5WorkDay: '',
      currDt: '',
      test_srcdt: '',

      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.'
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.pMAtrUnTrsfCseVO.filter(item => item.checked)
    },
    checkCount () {
      return this.pMAtrUnTrsfCseVO.filter(item => item.checked).length
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.pMAtrUnTrsfCseVO.length && this.pMAtrUnTrsfCseVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
    this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
    this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
    this.lv_inputParam.HofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 자동이체일 조회
    // 개발.검증일 경우 업무config의 영업일로 세팅  후  +10일 영업일리스트  재조회 (20211221_PJO)
    if ( process.env.NODE_ENV !== 'production' ) {
      this.serviceCallTest()
    } else {
      this.fn_WorkingDay()
    }

    // 자동이체미이체 리스트 조회
    this.fn_Submit()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
  },


  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }
    },
    
    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet() {
      let lv_Vm = this

      // 바텀시트 초기화
        if (lv_Vm.$refs !== undefined) {
          if (lv_Vm.$refs.bottomsheetMsg1 !== undefined) {
            lv_Vm.$refs.bottomsheetMsg1.close()
          }
        }
    },

    /******************************************************************************
    * 영업일 컨피그값으로 세팅
    * (개발,검증 테스트용)
    * (20211221_PJO)
    ******************************************************************************/
    serviceCallTest () {
      let lv_Vm = this
      let btnId = 'S'
      let pParams = {}
      pParams = {envId: 'SMS_TEST_SRCDT'} // 문자_테스트_영업일
      this.post(lv_Vm, pParams, 'txTSSAD02S3', btnId)
        .then(function (response) {
          if (response.body !== null) {
            let vList = response.body.returnDtlList
            if ( vList.length > 0 ) {
              for (const idx in vList) {
                const item = vList[idx]
                let o = {}
                o = Object.assign({}, item)
                o.label = item.smoUnitl // ID
                if (o.label === 'SRCDT' ) {
                  lv_Vm.test_srcdt = item.envCntnt // 값
                  lv_Vm.currDt = lv_Vm.test_srcdt
                  lv_Vm.fn_WorkingDay(lv_Vm.currDt)
                }
              } // for~
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_WorkingDay
    * 설명       : 현재일 기준 이전/이후 자동이체일 조회
    *
    ******************************************************************************/
    fn_WorkingDay (currDt) {
      let lv_Vm = this
      const trnstId = 'txTSSPM06S1'
      const auth = 'S'
      let pParams = {}
      pParams.currDt = currDt
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body) {
            lv_Vm.is5PlusWorkingDay = response.body.is5PlusWorkingDay
            lv_Vm.before5WorkDay = response.body.before5WorkDay
            lv_Vm.next5WorkDay = response.body.next5WorkDay
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('pm')['EPMC028']) // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
          if (!_.isEmpty(error.message)) {
            lv_Vm.getStore('confirm').dispatch('ADD', error.message)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('pm')['EPMC028']) // 시스템 오류가 발생했습니다. \n관리자에게 문의하여 주세요.
          }
        })
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      let lv_Vm = this

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (!_.isEmpty(this.pOrgData910M) && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData910M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      lv_Vm.stndKeyVal = ''
      lv_Vm.totCnt = 0
      lv_Vm.pMAtrUnTrsfCseVO = [] // 인피니트 바인딩 데이터
      lv_Vm.isEmptyView = false // 데이터가 없습니다 visible 여부
      lv_Vm.fn_DataSet() // 조회 파라미터 세팅

      lv_Vm.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      let lv_Vm = this

      // 지점장 조회시 구분
      if (lv_Vm.pOrgData910M.cnslt !== undefined && lv_Vm.isMngr === true) {
        lv_Vm.lv_inputParam.cnsltNo  = lv_Vm.pOrgData910M.cnslt.key // 선택한 컨설턴트 번호
        lv_Vm.lv_inputParam.orgNo = lv_Vm.pOrgData910M.fofOrg.key   // 선택한 지점조직번호
      } else {
        lv_Vm.lv_inputParam.cnsltNo  = lv_Vm.lv_cnsltNo // 컨설턴트 번호
        lv_Vm.lv_inputParam.orgNo = lv_Vm.userInfo.onpstFofOrgNo // 지점조직번호
      }
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출(자동이체미이체 리스트 조회)
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') return

      const trnstId = 'txTSSPM40S4'
      const auth = 'S'
      const lv_Vm = this
      let inputData = lv_Vm.lv_inputParam
      let confirm = this.getStore('confirm')

      lv_Vm.isLoading = true

      inputData.stndKeyList = lv_Vm.stndKeyList
      inputData.pageRowCnt = lv_Vm.pageRowCnt
      return this.post(this, inputData, trnstId, auth)
        .then(lv_Vm.fn_GetContractInfoHandler)
    },
    /******************************************************************************
    * Function명 : fn_GetContractInfoHandler
    * 설명       : 데이터를 가져와 필요한 처리와 화면에 뿌려주는 함수
    *
   ******************************************************************************/
    fn_GetContractInfoHandler (response) {
      const lv_Vm = this

      if (!response || !response.body) {
        return
      }
      // 서버 데이터 가져오기
      if (response.body.pMAtrUnTrsfCseVO !== null) {
        let responseList = response.body.pMAtrUnTrsfCseVO
        let tmp = []
        let lv_idx = 0
        responseList.forEach((item) => {
          // item.markUpContNo = '*' + item.contNo.substr(13, 4)
          tmp = item.bnkNm.toString().split('은')
          tmp = tmp[0].toString().split('금')
          tmp = tmp[0].toString().split('(')
          item.bnkNm = tmp[0]
          item.idx = lv_idx
          item.checked = false  // 리스트 체크변수 적용
          lv_Vm.pMAtrUnTrsfCseVO.push(item)
          lv_idx++
        })

        // 검색조건이 있는 경우 filter
        if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.searchKeyword)) {
          let regex = new RegExp(`^${lv_Vm.searchKeyword}`)
          lv_Vm.pMAtrUnTrsfCseVO = lv_Vm.pMAtrUnTrsfCseVO.filter(item => regex.test(item.contrNm))
        }

        // 조회 총 카운트
        lv_Vm.totCnt = lv_Vm.pMAtrUnTrsfCseVO.length

        // 더보기 키값
        lv_Vm.stndKeyList = []
        if (response.trnstComm.stndKeyList[0]) {
          lv_Vm.stndKeyList.push(response.trnstComm.stndKeyList[0])
          if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
          // 다음페이지 데이터 존재안함(마지막 페이지)
            lv_Vm.stndKeyVal = 'end'
          } else {
          // 다음페이지 데이터 존재함
            lv_Vm.stndKeyVal = 'more'
          }
        }

        if (responseList.length === 0) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
        } else {
          lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
        }
      } else {
        lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
      }
      
      lv_Vm.isLoading = false
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_LoadingData () {
      let lv_Vm = this

      if (!lv_Vm.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (lv_Vm.isOneOrMoreCheck && lv_Vm.stndKeyVal !== 'end') {
          lv_Vm.isOneOrMoreCheck = false
        }

        lv_Vm.fn_GetService()
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      this.$nextTick(() => {
        if (this.pMAtrUnTrsfCseVO.length > 0) { // 리스트 존재시에만 작동
          // 30명이상 선택 제한
          if (this.pMAtrUnTrsfCseVO.length > 30) {
            this.isOneOrMoreCheck = false
            this.$addSnackbar('30명 이상 선택할 수 없습니다.')
            return
          }
        
          if (this.is5PlusWorkingDay === false) {
            this.isOneOrMoreCheck = false
            // this.$pmUtil.getFDPConfirm('s', this.$t('pm')['EPMC029']) // "EPMC029": "자동이체일+1영업일에만 발송 가능합니다."
            this.$refs.bottomsheetMsg1.open()
            return
          } 

          for (let n in this.pMAtrUnTrsfCseVO) {
            if (this.isOneOrMoreCheck) {
              this.pMAtrUnTrsfCseVO[n].checked = true
            } else {
              this.pMAtrUnTrsfCseVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItemCount()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount (custInfo) {
      let lv_Vm = this

      if (lv_Vm.is5PlusWorkingDay === false) {
        custInfo.checked = false
        // lv_Vm.$pmUtil.getFDPConfirm('s', this.$t('pm')['EPMC029']) // "EPMC029": "자동이체일+1영업일에만 발송 가능합니다."
        lv_Vm.$refs.bottomsheetMsg1.open()
        return
      }

      // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      for (let i in lv_Vm.selectItems) {
        lv_Vm.selectItems[i].chnlCustId = lv_Vm.selectItems[i].chnlCustId01
        lv_Vm.selectItems[i].custNm = lv_Vm.selectItems[i].contrNm
      }
      lv_Vm.selectItems.busnCd = 'PM'
      lv_Vm.selectItems.before5WorkDay = lv_Vm.before5WorkDay
      lv_Vm.selectItems.next5WorkDay = lv_Vm.next5WorkDay
      lv_Vm.$cmUtil.setSelectedCustList(lv_Vm, lv_Vm.selectItems)
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}
      
      if(this.pOrgData910M.cnslt === undefined || this.pOrgData910M.cnslt === null || this.pOrgData910M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData910M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId01, //고객채널 ID
        parentId   : 'MSPCM180M', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.contrNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * Function명 : fn_MoveMSPPM931M
     * 설명       : 보험계약기본사항 메인으로 이동(2차 개발)
     *              callerId : 송신화면, chnlCustId : 채널아이디 ,
     *              viewId : 수신화면(기본값: null || 'MSPPM932D'), 
     *              [!!필수값!!] cnsltNo - 컨설턴트번호, contNo  - 계약번호
     * 작성일     : 23.03.23 
     * 작성자     : 서영화 
     * @param {String} contNo     계약번호
     * @param {String} chnlCustId 채널아이디 
     ******************************************************************************/
    fn_MoveMSPPM931M (contNo, chnlCustId) {
      let contInfoObj = {
        viewId: null,                         
        callerId: this.$options.screenId,     
        chnlCustId: chnlCustId,               
        cnsltNo: this.lv_cnsltNo,               
        contNo: contNo,                       
      }

      this.$router.push({name: 'MSPPM931M', params: contInfoObj})
    },

    /******************************************************************************
     * Function명 : fn_ContNoDecrypt
     * 설명       : 계약번호 복호화
     * 작성자     : 엄기문
     ******************************************************************************/
    async fn_ContNoDecrypt(_string, chnlCustId) {
      let prcContNo = ''
      if(!this.$bizUtil.isEmpty(_string)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this,[_string])
        prcContNo = prcContNo[0]
      }
      this.fn_MoveMSPPM931M(prcContNo, chnlCustId)
    },

    /*********************************************************
     * Function명 : fn_OpenMSPPM910P
     * 설명       : 상세조회조건 팝업 호출
     * @param {Object} - 설정된 상세조회조건
     * @return {Object} - 설정된 상세조회조건
     *********************************************************/
    fn_OpenMSPPM910P () {
      this.popup910 = this.$bottomModal.open(MSPPM910P, {
        properties: {
          pIsMngr: this.isMngr, // 관리자여부
          pOrgData: _.cloneDeep(this.pOrgData910M), // 조직정보
          pSearchCustNm: this.searchKeyword, // 계약자명
          pLpsScCd: _.cloneDeep(this.lv_inputParam.lpsScCd), // 계약구분
          pPadCylNm: _.cloneDeep(this.lv_inputParam.padCylNm), // 납입주기
          pAtrDd: _.cloneDeep(this.lv_inputParam.atrDd), // 자동이체일
          pUkepNtsScCd: _.cloneDeep(this.lv_inputParam.ukepNtsScCd) // 유지횟수
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup910)

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData910M = pData.rtnData.pOrgData
            // 나머지 데이터 세팅
            this.searchKeyword = pData.rtnData.searchCustNm
            this.lv_inputParam.lpsScCd = pData.rtnData.lpsScCd
            this.lv_inputParam.padCylNm = pData.rtnData.padCylNm
            this.lv_inputParam.atrDd = pData.rtnData.atrDd
            this.lv_inputParam.ukepNtsScCd = pData.rtnData.ukepNtsScCd

            // 자동이체미이체 리스트 조회
            this.fn_Submit()
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.popup910)
          }
        }
      })
    },

    /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      ******************************************************************************/
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.$route.query?._isMenuItem) {
                this.$router.push({name: 'MSPBC002M'})
                return
              }
              this.$router.go(-1)
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /**
     * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
     * @param       {Number} index 리스트 데이터의 index
     */
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },
    

    
  }
}
</script>
<style scoped>
.w-break {
  word-break: break-all !important;
}
</style>